.no-decoration {
  text-decoration: none;
  color: inherit;
  transition: all 0.7s ease;
}

.contact {
  text-decoration: none;
  color: inherit;
  transition: all 0.7s ease;
}

.contact:hover {
  color: lightblue;
  scale: 1.08;
}

.parallaxContact {
  color: white;
  -webkit-backdrop-filter: blur(6px); /* Safari 9+ */
  backdrop-filter: blur(6px); /* Chrome and Opera */
  box-shadow: inset 0 0 200px 200px rgba(0, 0, 0, 0.3);
}

.lightblue {
  color: lightblue;
}

@media screen and (max-width: 600px) {
  .contact {
    font-size: 0.75em;
  }
  .contact:hover {
    font-size: 0.85em;
  }
}

@media screen and (max-width: 450px) {
  .contact {
    font-size: 0.6em;
  }
  .contact:hover {
    font-size: 0.7em;
  }
}
