.parallaxContent {
  position: relative;
  padding: 10px;
  -webkit-backdrop-filter: blur(6px); /* Safari 9+ */
  backdrop-filter: blur(6px); /* Chrome and Opera */
  box-shadow: inset 0 0 200px 200px rgba(0, 0, 0, 0.2);
}

.card {
  border: 3px solid var(--color-1);
  border-radius: 10px;
}
