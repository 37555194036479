:root {
  --color-0: #0d1b2a;
  --color-1: #1b263b;
  --color-2: #415a77;
  --color-3: #778da9;
  --color-4: #e0e1dd;
}

.text-0 {
  color: var(--color-0) !important;
}
.text-1 {
  color: var(--color-1) !important;
}
.text-2 {
  color: var(--color-2) !important;
}
.text-3 {
  color: var(--color-3) !important;
}
.text-4 {
  color: var(--color-4) !important;
}

.bg-0 {
  background-color: var(--color-0) !important;
}
.bg-1 {
  background-color: var(--color-1) !important;
}
.bg-2 {
  background-color: var(--color-2) !important;
}
.bg-3 {
  background-color: var(--color-3) !important;
}
.bg-4 {
  background-color: var(--color-4) !important;
}
