a {
  text-decoration: none;
}

.navbar-tabs,
.navbar-slide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
  background: var(--color-0);
  text-align: center;
}

.active {
  font-weight: 900;
  font-size: 1.5em;
  color: var(--color-1);
  background-color: var(--color-3);
}

.navbar-tabs-container {
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 0;
  width: 100%;
  min-width: 100%;
  height: 70px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  background: var(--color-2);
  z-index: 10;
}

.navbar-tabs-container--top {
  position: fixed;
  top: 0;
}

.navbar-tab {
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  flex: 1;
  color: white;
  letter-spacing: 0.05rem;
  transition: all 0.5s ease;
  font-size: 1.3rem;
  font-family: "Raleway";
}
.navbar-tab:hover {
  color: white;
  background: rgba(102, 177, 241, 0.8);
  transition: all 0.5s ease;
  font-weight: bold;
  font-size: 1.4rem;
}

.language-button {
  display: flex;
  flex: 0.15;
  margin: 0 15px;
  padding: 15px 0;
}

@media screen and (max-width: 600px) {
  .navbar-tab {
    font-size: 0.85rem;
  }
  .navbar-tab:hover {
    font-size: 0.9rem;
  }
  .active {
    font-size: 0.9rem;
  }
  .language-button {
    display: flex;
    flex: 0.4;
  }
}
