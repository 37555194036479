.codeParallax {
  overflow: hidden;
  position: relative;
  height: 100vh;
  width: 100vw;
  max-width: 100vw;
}

.codeParallaxContent {
  position: relative;
  top: 20vh;
  padding: 10px;
  -webkit-backdrop-filter: blur(6px); /* Safari 9+ */
  backdrop-filter: blur(6px); /* Chrome and Opera */
  box-shadow: inset 0 0 0 200px rgba(32, 32, 32, 0.5);

  font-family: "Raleway";
  border-radius: 30px;
}

.main {
  font-size: 6em;
  font-weight: bold;
  color: #a7daff;
}

.second {
  font-size: 2em;
  font-weight: bold;
  color: #bee9e8;
}

@media screen and (max-width: 600px) {
  .main {
    font-size: 5em;
  }
}

@media screen and (max-width: 450px) {
  .main {
    font-size: 4em;
  }
}
