.aboutMe {
  font-size: 1.15em;
  font-family: "Lato";
}

.skills {
  font-size: 1.3em;
}

@media only screen and (max-width: 1200px) {
  .aboutMe {
    font-size: 1.1em;
  }
}
